import moment from 'moment-timezone';

export class ValidadorData {
    form = {};
    constructor(formComData) {
        this.form = formComData;
    }

    isDataNascimentoNoPassado() {
        const { nascimento } = this.datasFormatadas();

        if (!nascimento) return true;
        const dataAgora = moment().toDate();
        return moment(nascimento).isBefore(dataAgora);
    }

    datasFormatadas() {
        const admissao = this.getDataFormatada(this.form?.admissionDate);
        const nascimento = this.getDataFormatada(this.form?.dataNascimento);
        return { nascimento, admissao };
    }

    getDataFormatada(data) {
        if (!moment(data, 'DD/MM/YYYY', true).isValid()) {
            return null;
        }
        const [dia, mes, ano] = data.split('/');
        return data && ano ? moment(`${ano}-${mes}-${dia}`).toDate() : null;
    }

    isDataAdmissionalDepoisDaDataDeNascimento() {
        const { admissao, nascimento } = this.datasFormatadas();
        return !!moment(admissao).isAfter(nascimento);
    }

    isIdadeAcimaDos14Anos() {
        if (!this.form?.dataNascimento || this.form?.dataNascimento?.length < 4) {
            return false;
        }
        const nascimento = moment(this.form.dataNascimento, 'DD/MM/YYYY');
        const catorzeAnosAtras = moment().subtract(14, 'years');
        const idadeAcimaDos14Anos = nascimento.isBefore(catorzeAnosAtras);
        return idadeAcimaDos14Anos;
    }

    isDataNascimentoMenorQue100AnosAtras() {
        const { nascimento } = this.datasFormatadas();
        if (!nascimento) return false;
        const dataAgora = moment().toDate();
        return moment(nascimento).year() > moment(dataAgora).add(-99, 'years').year();
    }

    isDataAdmissaoMenorQue100AnosAtras() {
        const { admissao } = this.datasFormatadas();
        if (!admissao) return false;
        const dataAgora = moment().toDate();
        return moment(admissao).year() > moment(dataAgora).add(-99, 'years').year();
    }
}
