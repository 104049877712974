<template>
    <Dialog header="Multiplos Vinculos" :modal="true" :closable="false" :style="{ width: '35vw' }">

        <div class="formgrid grid">
            <div class="field col">
                <h5>Já existe um cadastro ativo com esse CPF. Deseja criar outro vínculo?</h5>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="fecharMultiplo" />
            <Button label="Confirmar" icon="pi pi-check" autofocus @click="salvarDuplicado" />
        </template>
    </Dialog>
</template>

<script>
import BaseService from '@/services/BaseService'

export default {
    props: ['form', 'formAntigo', 'fecharMultiplo'],
    model: {
        prop: ['form', 'formAntigo'],
        event: ['formchange', 'formAntigochange'],
    },
    data() {
        return {

        }
    },
    computed: {
        formLocal: {
            get: function () {
                return this.form || {}
            },
            set: function (value) {
                this.$emit('formchange', value)
            }
        },
        formAntigoLocal: {
            get: function () {
                return this.formAntigo || {}
            },
            set: function (value) {
                this.$emit('formAntigochange', value)
            }
        }
    },
    async mounted() {
        this.$service = new BaseService('/customer/employees')
    },
    methods: {
        async salvarDuplicado() {
            this.formLocal.multipleLinks = true;
            this.fecharMultiplo()
        },
    }
}
</script>
